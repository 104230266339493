﻿var alertCount = 0;

function warning(message, time = 2000) {
    alertCount++;

    var alerts = document.querySelector('#alerts');

    alerts.innerHTML = `
  <div id="alert${alertCount}">
    <div class="alert alert-warning">
      <span class="fa fa-warning"></span>
      <span class="message">
        ${message}.
      </span>
    </div>
  </div>
  ${alerts.innerHTML}
`;
    removeAlert('alert' + alertCount, time);

}
function info(message, time = 2000) {
    alertCount++;

    var alerts = document.querySelector('#alerts');

    alerts.innerHTML = `
  <div id="alert${alertCount}">
    <div class="alert alert-info">
      <span class="fa fa-info"></span>
      <span class="message">
        ${message}.
      </span>
    </div>
  </div>
  ${alerts.innerHTML}
`;

    removeAlert('alert' + alertCount, time);
}
function success(message, time = 2000) {
    alertCount++;

    var alerts = document.querySelector('#alerts');

    alerts.innerHTML = `
  <div id="alert${alertCount}">
    <div class="alert alert-success">
      <span class="fa fa-check"></span>
      <span class="message">
        ${message}.
      </span>
    </div>
  </div>
  ${alerts.innerHTML}
`;
    removeAlert('alert' + alertCount, time);

}
function danger(message, time = 2000) {
    alertCount++;

    var alerts = document.querySelector('#alerts');

    alerts.innerHTML = `
  <div id="alert${alertCount}">
    <div class="alert alert-danger">
      <span class="fa fa-exclamation"></span>
      <span class="message">
        ${message}.
      </span>
    </div>
  </div>
  ${alerts.innerHTML}
`;


    removeAlert('alert' + alertCount, time);
}

function loader(message) {
    if (document.getElementById('loader') == null)

    {
        var alerts = document.querySelector('#alerts');

        alerts.innerHTML = `
  <div id="loader">
    <div class="alert loader">
     <img src="Content/img/ball-triangle.svg" style="height: 30px"/><span class="message">
      <span class="message">
        ${message}.
      </span>
    </div>
  </div>
  ${alerts.innerHTML}
`;
    }
}

var removeLoader = function () {
    document.getElementById('loader').remove();
}

var removeAlert = function (id, time) {
    var length = document.getElementById('alerts').children.length;
    if (length > 7) {
        if (length > 14) {
            alert("brawo! udało Ci się wyklikać ponad 14 razy ( ͡° ͜ʖ ͡°), Jesteśmy z Ciebie dumni");
        }
        time = 1550;
    }

    setTimeout(function () {
        document.getElementById(id).remove();
    }, time);
}
